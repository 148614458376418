.ril-toolbar ,
.ril__toolbar{
    justify-content: flex-end !important;
}
@font-face {
    font-family: 'Poppins-SemiBold';
    src: url(../src/views/Pages/Login/font/Poppins-SemiBold.ttf);
  }
  
  @font-face {
    font-family: 'Poppins-Regular';
    src: url(../src/views/Pages/Login/font/Poppins-Regular.ttf);
  }
  
  body {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* font-family: 'Poppins-Regular'; */
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  } 
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    /* font-family: 'Poppins-Regular'; */
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    zoom: 1;
  }

/*Color Variable*/

:root {
  --main-black: #000;
  --main-white: #ffffff;
  --dark-gray-text: #4C606D;
  --light-gray-text: #808080;
  --thm-color-red: #D80707;
  --thm-color-blue: #0384C8;
  --thm-color-red: #D80707;
  --tfoot-bg-color: #7EC3F1;
  --footer-text-color: #23282C;
  --secondary-thm-color: #ef3d3c;
  --text-gray: #969696;
  --col-gray: #eeecec;
  --col-yellow: #FFF3CB;
  --col-orange: #FBE4D5;
  --col--dark-gray: #D6DCE4;
  --col-green: #d7eaca;
  --table-even-color: #e8f3ff;
  --table-even-color: #d8070721;
  --light-gray: #F9F9F9;
  --light-yellow: #FFFAEA;
  --light-orange: #FFF8F4;
  --light-dark-gray: #e6ecf4;
  --light-green: #F5FFEF;
  --col-blue: #c6f0ff;
  --light-blue: #e8f9ff;
  --border-color: #c1c1c1;
}


/*Color Variable*/

